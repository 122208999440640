.contact{
    margin: .5rem;
    display: grid;
}

.contact > .name{
    font-weight: bold;
}

.contact > .email{
    display: flex;
}

.contact > .website{
    display: flex;
}

@media only screen and (max-width: 600px) {
    .contact{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }             
    .contact > .name{
        grid-column: 1/5;
        grid-row: 1/1;
    }
    .contact > .email{
        justify-content: center;
        
        grid-column: 1/3;
        grid-row: 2/2;
    }
    .contact > .website{
        justify-content: center;
        
        grid-column: 3/5;
        grid-row: 2/2;
    }
}

.contact{
    grid-template-columns: 1fr 1fr 1fr;
}
.contact > .email{
    justify-content: flex-start;
}
.contact > .website{
    justify-content: flex-end;
}